export default () => {
  const entityForm = $('[selector="entity-filter-form"]')
  const fullSpinner = $('.full-spinner')
  const entitySelect = $('[selector="entity-filter-select"]')

  // Store initial value
  entitySelect.data('data-prev', entitySelect.val())

  entitySelect.select2({
    width: null
  })

  // Entity filter on change
  entitySelect.on('change', e => {
    const previousOption = entitySelect.data('data-prev')
    const newOption = entitySelect.val()

    // Only submit if different
    if (previousOption !== newOption) {
      entitySelect.data('data-prev', newOption)
      fullSpinner.css('display', 'block')
      entityForm.submit()
    }
  })
}
