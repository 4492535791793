import { Controller } from '@hotwired/stimulus'
import Pikaday from 'pikaday'
import moment from 'moment'

export default class extends Controller {
  static targets = ["calendar", "organizer"]

  connect () {
    const data = this.element.dataset

    if (data.event_with_calendar == "true"){
      const momentDates = Object.keys(JSON.parse(data.data)).map(date => moment(date))
      new Pikaday({
        field: this.calendarTarget,
        container: this.calendarTarget,
        bound: this.bound(),
        defaultDate: moment(data.next_session_date).toDate(),
        setDefaultDate: true,
        minDate:  moment(data.start_date).toDate(),
        maxDate: moment(data.end_date).toDate(),
        i18n: this.getLanguage(),
        disableDayFn: dateToCheck => !momentDates.some(momentDate => momentDate.isSame(dateToCheck)),
        onSelect: this.updateOrganizer.bind(this),
        events: this.getEvents(data.selected_dates)
      })
      this.updateOrganizer(data.next_session_date)
    }
  }

  updateOrganizer (ev) {
    const date_key = moment(ev).format("YYYYMMDD")
    const location_id = this.element.dataset.location_id

    this.organizerTarget.querySelectorAll(".organizer_session__wrapper")
    .forEach(organizer_day => organizer_day.style.display = "none")

    // open all days with selected sessions
    if (this.element.dataset.selected_dates != "null"){
      const dates = this.getEvents(this.element.dataset.selected_dates)
      dates.map(date => {
        const formatedDate =  moment(Date.parse(date)).format("YYYYMMDD")
        this.organizerTarget.querySelector(".organizer_day_" + formatedDate).style.display = "none"
        document.dispatchEvent(new Event(`calendar_${location_id}_${formatedDate}`))
      })
    }
    
    this.organizerTarget.querySelector(".organizer_day_" + date_key).style.display = "block"
    document.dispatchEvent(new Event(`calendar_${location_id}_${date_key}`))

    // update checkboxes when day is changed in pickaday
    $('.has-event').each(function () {
      $(this).removeClass('has-event')
    })
    $('.session_checkbox').each(function () {
      if (this.checked === true) {
        const date = this.id.split('_')[2]
        const day = parseInt(date.slice(-2))
        const month = parseInt(date.slice(-4, -2)) - 1
        const year = parseInt(date.slice(0, 4))
        if ($(`[data-pika-year='${year}'][data-pika-month='${month}'][data-pika-day='${day}']`)) {
          $(`[data-pika-year='${year}'][data-pika-month='${month}'][data-pika-day='${day}']`).first().parent().get(0).classList.add('has-event')
        }
      }
    })

    $('#calendar_input').val(moment(ev).format("DD/MM/YYYY"))
  }

  getLanguage () {
    return {
      months        : moment.localeData("pt")._months,
      weekdays      : moment.localeData("pt")._weekdays,
      weekdaysShort : moment.localeData("pt")._weekdaysShort
    }
  }

  getEvents (dates) {
    let formatedDates = []
    const parsedDates = JSON.parse(dates)
    if(parsedDates != null){
      formatedDates = parsedDates.map(date => {
        return moment(date).format('ddd MMM DD YYYY')
      })
    }
    return formatedDates
  }

  bound () {
    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        isMobile = true;
    }
    if(isMobile){
      $('#calendar_input').show()
    } else {
      $('#calendar_input').hide()
    }
    return isMobile ? true : false
  }


}
