import { setupChartLegendHover, setupEntityFilter, setupSalesByLocalSwitch, setupSalesEvolutionSwitch } from '../../../services'
import Rails from '@rails/ujs'
import NonBlockedOccupationLogic from '../../events/partials/non_blocked_occupation_logic'

$(document).on('turbolinks:load', () => {
  if ($('#general-dashboard').length !== 0) {
    setupEntityFilter()
    setupSalesByLocalSwitch()

    const $periodForm = $('#period_filter')
    const fullSpinner = $('.full-spinner')

    // Set sales evolution chart properties
    setupSalesEvolutionSwitch()
    // Set sales by local chart properties
    setupSalesByLocalSwitch()

    // Set sales by pos chart properties
    $(document).on('sales_by_pos_loaded', _ => {
      setupChartLegendHover('sales_by_pos_chart')
    })

    // Set sales by event chart properties
    $(document).on('sales_by_event_loaded', _ => {
      setupChartLegendHover('sales_by_event_chart')

      // If the url has the tour anchor, scroll there
      const scrollId = '#total-occupation-scroll'
      if (location.hash.includes(scrollId)) {
        const updateScroll = () => {
          $('html, body').animate({
            scrollTop: $(scrollId).offset().top - 80 // Remove nav height
          }, 200)
        }
        // create an Observer instance
        const resizeObserver = new ResizeObserver(() =>
          // console.log('Body height changed:', entries[0].target.clientHeight)
          updateScroll()
        )

        // start observing a DOM node
        resizeObserver.observe(document.body)
      }
    })

    // Period filter set loading spinner
    $('#period_filter .select').on('change', e => {
      const selectedPeriod = $(e.target).find(':selected').val()
      const customFilterValue = $(e.target).attr('data-custom-filter')

      if (selectedPeriod === customFilterValue) {
        $('.dashboard_date_filter').css('display', 'block')
      } else {
        fullSpinner.css('display', 'block')
        $periodForm.submit()
      }
    })

    $('.dashboard_date_filter__submit input').click((e) => {
      fullSpinner.css('display', 'block')
      $periodForm.submit()
    })

    // Verify correctness on date updates
    $('#filter_start_date').on('dp.change', function (e) {
      $('#filter_end_date').data('DateTimePicker').minDate(e.date)
    })

    $('#filter_end_date').on('dp.change', function (e) {
      $('#filter_start_date').data('DateTimePicker').maxDate(e.date)
    })

    // On occupation list load
    $(document).on('total_occupation_list_loaded', _ => {
      // Setup selectors
      $('[select2-target="venue-filter"]').select2({ width: null })
      new NonBlockedOccupationLogic().setup()
      const $venueForm = $('#venue_filter')

      // Venue filter set loading spinner
      $('#venue_filter .select').on('change', e => {
        fullSpinner.css('display', 'block')

        // Submit method (form.submit()) doesn't generate a submit event.
        // By consequence, no event reaches document, not triggering UJS behavior,
        // which is necessary to make the request **remote** work.
        Rails.fire($venueForm.get(0), 'submit')
      })
    })
  }
})
