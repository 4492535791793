/* global $ */
import objectDeepMerge from 'deepmerge'
import { Controller } from '@hotwired/stimulus'
import { DistrictsMap, defaultOptions } from 'portugal-maps'
import { hsl2hex } from '../../services'
import i18n from "../../initializers/i18n";

export default class extends Controller {
  connect () {
    const $this = $(this.element)

    const containerId = 'portugal-map-container'
    const mapContainer = jQuery('<div/>', {
      id: containerId
    })
    $this.append(mapContainer)

    this.districtsMap = new DistrictsMap({
      selectionEnabled: false,
      tooltipEnabled: true,
      districtOptions: this.districtTooltipOptions,
      height: '500px',
      stroke: {
        color: 'white'
      },
      unselected: {
        color: '#edecec',
        hover: {
          color: '#edecec'
        }
      }
    })

    this.districtsMap.render(`#${containerId}`)
  }

  get districtData () {
    return JSON.parse(this.data.get('district-data')) || {}
  }

  get districtTooltipOptions () {
    // Get the district codes by Name
    const districtNames = defaultOptions.districtNames
    const districtCodesByName = Object.fromEntries(Object.entries(districtNames).map(a => a.reverse()))

    // Convert chart data to tooltip options
    const chartData = this.districtData
    return Object.keys(districtCodesByName).reduce((res, districtName) => {
      const districtCode = districtCodesByName[districtName]
      res[districtCode] = this.formattedDistrictOptions(chartData[districtName] || {})
      return res
    }, {})
  }

  formattedDistrictOptions (districtData) {
    let options = {
      tooltip: {
        body: `<div>${districtData.ticket_count || 0} ${i18n.t('seats_sold')} <br>${districtData.value || '0 €'}<br>${(districtData.percentage * 100 || 0).toFixed(2)} %</div>` // TODO: Improve tooltip body
      }
    }

    if (districtData.percentage > 0) {
      const districtColor = this.getDistrictColor(districtData.percentage)
      options = objectDeepMerge(options, {
        unselected: {
          color: districtColor,
          hover: {
            color: districtColor
          }
        }
      })
    }

    return options
  }

  getDistrictColor (percentage) {
    percentage = percentage || 0
    const GREEN_HUE = 75
    const GREEN_SAT = 70
    const DARKER_LIGHT = 15
    const LIGHTER_LIGHT = 85

    const newLightness = LIGHTER_LIGHT - percentage * (LIGHTER_LIGHT - DARKER_LIGHT)

    return hsl2hex(GREEN_HUE / 360, GREEN_SAT / 100, newLightness / 100)
  }
}
