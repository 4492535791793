import { setupEntityFilter } from '../../../services'

$(document).on('turbolinks:load', () => {
  if ($('#saft').length !== 0) {
    // Setup selectors
    $('[select2-target]').select2({
      width: '100%'
    })

    setupEntityFilter()
    setupFormFilter('financial_report_form', 'financial_report_entity_id')
    setupFormFilter('saft_form', 'selected_year')

    const $form = $('#financial_report_form')
    // const $fullSpinner = $('.full-spinner')

    // $('select#event_id').on('change', _e => {
    //   $fullSpinner.css('display', 'block')
    //   $form.submit()
    // })

    $('[selector="financial-report-submit"]').on('click', e => {
      e.preventDefault()
      e.stopPropagation()

      // Set form action to correct extension
      const currEl = $(e.target)
      // $fullSpinner.css('display', 'block')
      let action
      // Fetch action from the correct element
      if (currEl.attr('selector') === 'financial-report-submit') {
        action = currEl.attr('href')
      } else {
        action = $(e.target).closest('[selector="financial-report-submit"]').attr('href')
      }
      $form.attr('action', action)
      $form.submit()
    })

    // Setup saft list download
    const saftForm = $('[saft-form-target="form"]')
    $('[saft-form-target="submit"]').on('click', function (event) {
      event.preventDefault()
      event.stopPropagation()

      // Set hidden value of start_end_date to the corresponding chosen saft
      $('[saft-form-target="hidden-dates"]').val(this.getAttribute('saft-form-period'))

      saftForm.trigger('submit.rails')
    })

    // Link the entities selector to update on each other change
    $('[entity_updatable="true"]').on('change', (event, updateParams) => {
      if (updateParams && !updateParams.update) return

      const newVal = $(event.target).val()
      const missingUpdatedElements = Array.from(document.querySelectorAll('[entity_updatable="true"]'))
        .filter(e => e !== event.target)
      missingUpdatedElements.forEach(currEl => {
        $(currEl).val(newVal).trigger('change', { update: false })
      })
    })
  }
})

function setupFormFilter (formId, selectId) {
  const form = $(`form#${formId}`)
  const fullSpinner = $('.full-spinner')
  const select = $(`form#${formId} #${selectId}`)

  // Store initial value
  select.data('data-prev', select.val())

  // Entity filter on change
  select.on('change', e => {
    const previousOption = select.data('data-prev')
    const newOption = select.val()

    // Only submit if different
    if (previousOption !== newOption) {
      // Required because the action was set to downloading
      // the financial report in some case, when changing entity name
      form.attr('action', '/saft')
      select.data('data-prev', newOption)
      fullSpinner.css('display', 'block')
      form.submit()
    }
  })
}
