import { Controller } from '@hotwired/stimulus'
import Pikaday from '../../../initializers/pikaday'
import moment from 'moment'
import i18n from '../../../initializers/i18n'
import * as Routes from 'routes.js.erb'

export default class extends Controller {
  static targets = ["calendar", "organizer"]

  connect () {
    this.element['pikaday'] = this
    $(document).ready(this.create())
  }

  update() {
    this.picker.destroy()
    // Hack to destroy residual information in the picker
    this.picker["_o"].trigger.value = ""
    if($("#range").prop("checked") == true){
      $("#calendar_type").val("range")
    } else {
      $("#calendar_type").val("dates")
    }
    this.create(true)
  }

  create (reset = false) {
    const data = this.element.dataset
    // reset all data and checkboxes
    if(reset == true){
      data.selected_dates = []
      this.selectedDates = []
      $('#all_visible_sessions').get(0).checked = false
    } else {
      this.selectedDates = data.hasOwnProperty("selected_dates") && data.selected_dates != "null" ? JSON.parse(data.selected_dates) : []
    }
    const $this = this
    if (data.event_with_calendar == "true"){
      const momentDates = JSON.parse(data.all_data).map(date => moment(date))
      this.picker = new Pikaday({
        field: this.calendarTarget,
        container: this.calendarTarget,
        bound: false,
        defaultDate: moment(data.calendar_start_date).toDate(),
        minDate: moment(data.start_date).toDate(),
        maxDate: moment(data.end_date).toDate(),
        firstDay: 1, // 1 == Monday
        i18n: this.getLanguage(),
        disableDayFn: dateToCheck => !momentDates.some(momentDate => momentDate.isSame(dateToCheck)),
        events: this.getEvents(this.selectedDates),
        onSelect: (date) => {
          // Format selected date
          const formattedDate = moment(date).format('YYYY-MM-DD');
            
          if($("#range").prop("checked") == true){
            if (this.selectedDates.length == 0){
              this.selectedDates.push(formattedDate)
            } else if (this.selectedDates.length == 1) {
              this.selectedDates = $this.getDaysArray(this.selectedDates[0], formattedDate)
            } else {
              this.selectedDates = [formattedDate]
            }
          } else {
            // Remove date if it was previous selected
            if (this.selectedDates.includes(formattedDate)) {
              this.selectedDates = this.selectedDates.filter((date) => date !== formattedDate);
            }
            // Otherwise add it to the selected dates
            else {
              this.selectedDates.push(formattedDate);
            }
          }
          $this.updateOrganizer(this.selectedDates) 
        },
        selectDayFn: (date) => {
            // Check if a day is selected
            const formattedDate = moment(date).format('YYYY-MM-DD');
            return this.selectedDates.includes(formattedDate);
        },
      })
      this.loadedMonths = data.hasOwnProperty("loaded_months") && data.loadedMonths != "null" ? JSON.parse(data.loaded_months) : []
      this.updateOrganizer(this.selectedDates)
    }
  }

  updateOrganizer(selectedDates) {
    // targeting calendar to tours 
    $(".pika-table button").parent().not('.is-disabled').get(0).setAttribute("checkpoint-target", "first_day")
    $(".pika-table button").parent().not('.is-disabled').get(0).setAttribute("scroll-target", "first_day")
    
    if(selectedDates !== "null"){
      const dates_key = selectedDates.map( date => moment(date).format("YYYYMMDD"))
      const location_id = this.element.dataset.location_id
  
      this.organizerTarget.querySelectorAll(".organizer_session__wrapper")
      .forEach(organizer_day => organizer_day.style.display = "none")

      if(dates_key.length === 0){
        $('#no_filterd_sessions_message').show()
        $('#all_visible_sessions_div').hide()
      } else {
        dates_key.forEach(date_key => {
          if (this.organizerTarget.querySelector(".organizer_day_" + date_key)){
            this.organizerTarget.querySelector(".organizer_day_" + date_key).style.display = "block"
            document.dispatchEvent(new Event(`calendar_${location_id}_${date_key}`))
          }
        })
        $('#no_filterd_sessions_message').hide()
        $('#all_visible_sessions_div').show()
      }
  
      $('#calendar_input').val(selectedDates.map( date => moment(date).format("DD/MM/YYYY")))
    }
    this.organizerTarget.querySelectorAll(".organizer_session__wrapper").forEach( organizer_day => {
      if(organizer_day.style.display == "none"){
        const checkboxes = organizer_day.querySelectorAll('input')
        for(const checkbox of checkboxes){
          if(checkbox.checked == true){
            checkbox.checked = false
            const event = new Event('change')
            checkbox.dispatchEvent(event)
          }
        }
      }
    })

    setTimeout(function() {
      // TODO:; Fix this logic to add this attr after the change date event
      if( $('.pika-next.is-disabled').length == 0){
        $('.pika-next').attr("data-action","click->components--events--calendar#nextMonth")
      }
      if( $('.pika-prev.is-disabled').length == 0){
        $('.pika-prev').attr("data-action","click->components--events--calendar#prevMonth")
      }
     }, 300);
  }

  nextMonth() {
    this.picker.nextMonth()
    this.updateMonth()
  }

  prevMonth() {
    this.picker.prevMonth()
    this.updateMonth() 
  }

  // Notes: this method is used because we want to lazyload the data (only fetch the month info if user is at that month in calendar) 
  // a lot is done in the success function because (for now) we rerender pikaday calendar for each (previously unloaded) month
  // We replace the html elements with the new month html elements and add some of the old data that is still needed.
  // A better approach would be to keep the old data and just append the new month data to it, without rerendering the calendar, 
  // However it was tricky to make it work, because eg. the render_async js code in our html did not get triggered in that approach.
  // Also I could not find an update method in Pikaday for us to update the config with new data.
  // Relevant render_async issue: https://github.com/renderedtext/render_async/issues/70
  updateMonth() {
    const dataset = this.element.dataset
    
    if(!this.loadedMonths.includes(`${this.currentMonth()}/${this.currentYear()}`)){
      $('#calendar_loading').show()
      $(`#calendar_${this.element.dataset.location_id}`).hide()

      $.ajax({
        url: Routes.event_sessions_async_path(dataset.event_id),
        type: 'POST',
        data: { content: 'new_month',
                event_with_calendar: dataset.event_with_calendar,
                location_id: dataset.location_id,
                all_data: dataset.all_data,
                permanent_event: dataset.permanent_event,
                calendar_type: dataset.calendar_type,
                sessions_count: dataset.sessions_count,
                selected_dates: this.selectedDates,
                loaded_months: this.loadedMonths,
                month: this.currentMonth(),
                year: this.currentYear(),
              },
        success: function (data) {
          const previousSessions = $(".organizer_session__wrapper")
          const previouslyRangeChecked = $("#range").get(0).checked
          const previouslyAllSessionsChecked = $('#all_visible_sessions').get(0).checked
          this.picker.destroy()
          
          $(".cal_org_body").replaceWith(data) // replace with new month information
          $(".organizer_session__wrapper").first().before(previousSessions) // insert the old sessions
          $('.organizer_session_container').html($('.organizer_session__wrapper').sort(this.sortByDate)) // order all sessions

          $('#all_visible_sessions').get(0).checked = previouslyAllSessionsChecked // keep the all sessions checkbox and calendar type info
          $("#range").get(0).checked = previouslyRangeChecked
          $('#calendar_loading').hide() // Replace loading spinner by calendar
          $(`#calendar_${this.element.dataset.location_id}`).show()
          if(previouslyRangeChecked) {
            $("#calendar_type").val("range")
          } else {
            $("#calendar_type").val("dates")
          }
          document.dispatchEvent(new Event('new-month-loaded'))
         }.bind(this)
      })
    } else {
        // This probably belongs somewhere else. It used to be done in pickaday natively (pikaday.js)
        // But we extracted the newMonth and prevMonth logic to do additional things, such as lazy loading data per month.
        // It needs to be run on every month load (see also updateOrganizer method where this logic happens after rendering pikaday calendar)
        if( $('.pika-next.is-disabled').length == 0){
          $('.pika-next').attr("data-action","click->components--events--calendar#nextMonth")
        }
        if( $('.pika-prev.is-disabled').length == 0){
          $('.pika-prev').attr("data-action","click->components--events--calendar#prevMonth")
        }
    }
  }

  // Adding 1 to conform to how rails defines months 
  // (1 to 12 instead of 0 to 11 like pickaday)
  currentMonth () {
    if (typeof this.picker !== "undefined") {
      return this.picker.calendars[0].month + 1
    }
  }

  currentYear () {
    if (typeof this.picker !== "undefined") {
      return this.picker.calendars[0].year
    }
  }

  getLanguage () {
    const locale = $('body').get(0).bodyController.locale
    i18n.locale = locale
    return {
      months        : i18n.t("date.month_names").slice(1),
      weekdays      : i18n.t("date.day_names"),
      weekdaysShort : i18n.t("date.abbr_day_names")
    }
  }

  getEvents (dates) {
    let formatedDates = []
    if(dates != null){
      formatedDates = dates.map(date => {
        return moment(date).format('ddd MMM DD YYYY')
      })
    }
    return formatedDates
  }

  /*bound () {
    var isMobile = false; //initiate as false
    // device detection
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
        isMobile = true;
    }
    if(isMobile){
      $('#calendar_input').show()
    } else {
      $('#calendar_input').hide()
    }
    return isMobile ? true : false
  }*/

  getDaysArray (startDate, endDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var endDate = moment(endDate);
    while (currentDate <= endDate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
    }
    return dateArray;
  };

  // Used to sort HTML elements by date. 
  // eg. element with organizer_day_20230608 should come before organizer_day_20230718
  sortByDate (a, b) {
    return a.className.split("organizer_day_").at(-1).localeCompare(b.className.split("organizer_day_").at(-1))
  }

}
